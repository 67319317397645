import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { Popconfirm, message } from 'antd';
import { postOrder, postOrderAdmin } from '../../../../services/orderServices';
import { MedalContext } from '../../../../context/Context';

const ModalCreateOrderForm = (props) => {
    const role = useSelector((state) => state.role.role);
    const { setReRender } = useContext(MedalContext);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);


    const { goBack, shopName, address, shopPhone, handleClose } = props;

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log(data);
        let fieldSubmitFormShop = {
            fullName: data.nameSender,
            phone: data.phoneSender,
            from: data.addressSender,
            to: data.address,
            note: data.note,
            money: +data.money,
            distance: null,
            key: "string",
            tempPrice: 0
        }
        if (role === 'ADMIN' || role === 'SUPERADMIN') {
            const newData = { ...fieldSubmitFormShop, shopName: shopName }
            await postOrderAdmin(newData)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            await postOrder(fieldSubmitFormShop)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleOk = (data) => {
        setConfirmLoading(true);
        onSubmit(data)
        setTimeout(async () => {
            await setOpen(false);
            setConfirmLoading(false);
            message.success('Tạo đơn hàng thành công');
            setReRender(Math.random())
            handleClose()
        }, 2000);
    };
    const handleCancel = () => {
        message.error('Hủy tạo đơn hàng');
        setOpen(false);
    };

    return (
        <form className="flex gap-[20px] w-full h-full justify-between" onSubmit={showPopconfirm}>
            {/* Thông tin người nhận */}
            <div className="flex-[3]">
                <div className="flex flex-col gap-[20px] py-[15px] rounded-[10px] bg-[#F5F5F5]">
                    <div className="flex flex-col px-[15px] gap-[20px]">
                        <span className="text-[18px] font-semibold text-[#F8C74F]">
                            Thông tin người nhận
                        </span>
                        <input
                            className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px]"
                            placeholder="Tên người nhận"
                            {...register("fullname", {
                                required: true,
                            })}
                        />
                        {errors?.name?.type === "required" && (
                            <p className="text-[#FF0000] text-[14px]">
                                Vui lòng không bỏ trống ô này
                            </p>
                        )}
                        <input
                            className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px]"
                            placeholder="Số điện thoại"
                            {...register("phone", {
                                required: true,
                                pattern: /^-?\d+\.?\d*$/,
                            })}
                        />
                        {errors?.phone?.type === "required" && (
                            <p className="text-[#FF0000] text-[14px]">
                                Vui lòng không bỏ trống ô này
                            </p>
                        )}
                        {errors?.phone?.type === "pattern" && (
                            <p className="text-[#FF0000] text-[14px]">
                                Vui lòng nhập đúng số điện thoại
                            </p>
                        )}
                        <textarea
                            className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px] resize-none"
                            rows="5"
                            placeholder="Địa chỉ người nhận"
                            {...register("address", {
                                required: true,
                                minLength: 6,
                            })}
                        ></textarea>
                        {errors?.address?.type === "required" && (
                            <p className="text-[#FF0000] text-[14px]">
                                Vui lòng không bỏ trống ô này
                            </p>
                        )}
                        {errors?.address?.type === "minLength" && (
                            <p className="text-[#FF0000] text-[14px]">
                                Địa chỉ nhập tối đa 6 ký tự
                            </p>
                        )}
                    </div>
                    <div className="px-[15px]">
                        <textarea
                            rows="6"
                            className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#A9A9A9] rounded-[3px] resize-none"
                            placeholder="Ghi chú:"
                            {...register("note", {
                                required: false,
                            })}>
                        </textarea>
                        {/* {errors?.note?.type === "required" && <p className='text-[#FF0000] text-[14px]'>Vui lòng không bỏ trống ô này</p>} */}
                    </div>
                    {
                        role !== 'SHOP' && (
                            <div className="flex-col gap-[20px] mt-[10px] ml-[20px]">
                                <Button variant="contained" onClick={goBack}>
                                    <IoMdArrowRoundBack size={40} />
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>

            {/* Thông tin người gửi */}
            <div className="flex-[4] h-full py-[15px] px-[15px] rounded-[10px] bg-[#F5F5F5] flex flex-col">
                <div className="flex flex-col  gap-[20px]">
                    <span className="text-[18px] font-semibold text-[#F8C74F]">
                        Thông tin người gửi
                    </span>
                    <input
                        className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px]"
                        placeholder="Tên người gửi"
                        {...register("nameSender", {
                            required: true,
                        })}
                        defaultValue={role === 'ADMIN' || role === 'SUPERADMIN' ? shopName : ''}
                    />
                    {errors?.name?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng không bỏ trống ô này
                        </p>
                    )}
                    <input
                        className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px]"
                        placeholder="Số điện thoại"
                        {...register("phoneSender", {
                            required: true,
                            pattern: /^-?\d+\.?\d*$/,
                        })}
                        defaultValue={role === 'ADMIN' || role === 'SUPERADMIN' ? shopPhone : ''}
                    />
                    {errors?.phone?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng không bỏ trống ô này
                        </p>
                    )}
                    {errors?.phone?.type === "pattern" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng nhập đúng số điện thoại
                        </p>
                    )}
                    <textarea
                        className="w-full px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px] resize-none"
                        rows="5"
                        placeholder="Địa chỉ người gửi"
                        {...register("addressSender", {
                            required: true,
                            minLength: 6,
                        })}
                        defaultValue={role === 'ADMIN' || role === 'SUPERADMIN' ? address.address : ''}
                    ></textarea>
                    {errors?.addressSender?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng không bỏ trống ô này
                        </p>
                    )}
                    {errors?.addressSender?.type === "minLength" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Địa chỉ nhập tối đa 6 ký tự
                        </p>
                    )}
                </div>
                <div className="flex flex-col gap-[20px] mt-[30px]">
                    <span className="text-[#F8C74F] text-[18px] font-semibold">
                        Nhập giá tiền
                    </span>
                    <input
                        className="w-[70%] px-[10px] py-[11px] border-solid border-[1px] border-[#F8C74F] rounded-[3px]"
                        placeholder="Nhập giá tiền"
                        {...register("money", {
                            required: true,
                            pattern: /^\d+$/,
                        })}
                    />
                    {errors?.money?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng không bỏ trống ô này
                        </p>
                    )}
                    {errors?.money?.type === "pattern" && (
                        <p className="text-[#FF0000] text-[14px]">
                            Vui lòng nhập đúng số tiền
                        </p>
                    )}
                </div>
                <div className="flex-[1] flex flex-col"></div>
                <div className="px-[20px] py-[25px] bg-[#FFE7F4] mx-[-15px] mb-[-15px] rounded-b-[10px] flex justify-between items-center">
                    <div className="flex flex-col gap-[10px]"></div>
                    <Popconfirm
                        className={`px-[23px] py-[12px] border-solid border-[1px] border-[#F8C74F] rounded-[3px] text-[#F8C74F] h-fit cursor-pointer opacity-100`}
                        title="Bạn có chắc chắn muốn tạo đơn hàng này không?"
                        open={open}
                        onConfirm={handleSubmit(handleOk)}
                        okButtonProps={{
                            loading: confirmLoading,
                        }}
                        onCancel={handleCancel}
                        placcement="top"
                    >
                        <button
                            type="submit"
                        // onClick={() => {
                        //    dispatch(addCart(ordercart));
                        //    dispatch(clearnOder());
                        // }}
                        >
                            <span className="font-bold text-[16px]">Tạo đơn</span>
                        </button>
                    </Popconfirm>
                </div>
            </div>
        </form>
    )
}

export default ModalCreateOrderForm;