import React, {useEffect, useState} from "react";
import "./styles.scss";
import {getListShipper,} from "../../../services/shipperServices";
import Notiflix from "notiflix";
import {useSelector} from "react-redux";
import {IoEarthOutline} from "react-icons/io5";
import {socketEmit,} from "../../../services/socketServices";
import MapGL, {
    FlyToInterpolator,
    FullscreenControl,
    GeolocateControl,
    NavigationControl,
    Popup,
    ScaleControl
} from '@goongmaps/goong-map-react';
import Pins from "./Pins";
import * as d3 from 'd3-ease';
import ShipperInfo from "./ShipperInfo";

const geolocateStyle = {
    top: 0,
    left: 0,
    padding: '10px'
};

const fullscreenControlStyle = {
    top: 36,
    left: 0,
    padding: '10px'
};

const navStyle = {
    top: 72,
    left: 0,
    padding: '10px'
};

const scaleControlStyle = {
    bottom: 36,
    left: 0,
    padding: '10px'
};

function TabShipperLocation() {
    const user = useSelector((state) => state.auth.user);
    const codeArea = useSelector((state) => state.area.codeArea);
    const role = useSelector((state) => state.role.role);

    const [params, setsParams] = useState({
        codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
        page: "1",
        limit: "10",
    });
    const [originalShippers, setOriginalShippers] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [panage, setPanage] = useState(1);
    const [countPage, setCountPage] = useState();
    const [allShippers, setAllShippers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPhoneShipper, setCurrentPhoneShipper] = useState();
    const [currentPointShipper, setCurrentPointShipper] = useState();
    const [fillterNum, setFillterNum] = useState("");
    const [searchResultMessage, setSearchResultMessage] = useState("");
    const [id, setId] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);

    // const handleLeft = () => {
    //     if (panage <= 1) {
    //         setPanage(1);
    //     } else {
    //         setPanage((prev) => prev - 1);
    //     }
    // };
    //
    // const handleRight = () => {
    //     if (panage >= countPage) {
    //         return;
    //     } else {
    //         setPanage((prev) => prev + 1);
    //     }
    // };

    // const getlist = async () => {
    //     console.log("Fetching shippers with params:", params); // Debugging log
    //     await getListShipperByArea(params)
    //         .then((data) => {
    //             console.log("API response:", data); // Debugging log
    //             setOriginalShippers(data.data.shippers);
    //             setCountPage(Math.ceil(data.data.shippers.length / 5)); // Tính tổng số trang
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    const getAllList = async () => {
        console.log("Fetching shippers with params:", params); // Debugging log
        await getListShipper()
            .then(async (data) => {
                console.log("API response:", data.data); // Debugging log
                const shipperActive = await data.data.shippers.filter((shipper) => shipper.statusShipper === true);

                console.log("--- Shipper active ---", shipperActive);
                setAllShippers(shipperActive);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    console.log("All shippers:", allShippers);

    //
    // useEffect(() => {
    //     getlist();
    // }, [params, countPage]);
    //
    // useEffect(() => {
    //     if (
    //         codeArea == null ||
    //         codeArea == "" ||
    //         (codeArea == undefined && role == "SUPERADMIN")
    //     ) {
    //         setsParams({...params, codeArea: undefined});
    //         return;
    //     }
    //     setsParams({
    //         ...params,
    //         codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    //     });
    // }, [codeArea]);

    // useEffect(() => {
    //     if (fillterNum === "") {
    //         getlist();
    //     }
    // }, [fillterNum]);
    //
    useEffect(() => {
        getAllList();
    }, []);

    // const filteredShippers = useMemo(() => {
    //     if (fillterNum === "") {
    //         return originalShippers;
    //     }
    //     return originalShippers.filter(
    //         (shipper) =>
    //             shipper.phone.includes(fillterNum) ||
    //             shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    //     );
    // }, [fillterNum, originalShippers]);


    // // Tự động tìm kiếm
    // useEffect(() => {
    //     // Khi thay đổi giá trị fillterNum, sẽ reset về trang 1 và lọc dữ liệu
    //     setPanage(1);
    //
    //     // Lọc dữ liệu dựa trên giá trị của fillterNum
    //     const filteredShippers = originalShippers.filter(
    //         (shipper) =>
    //             shipper.phone.includes(fillterNum) ||
    //             shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    //     );
    //
    //     // Cập nhật dữ liệu hiển thị theo trang và số lượng trang
    //     setShippers(filteredShippers.slice(0 * 5, 5)); // Reset về trang đầu tiên
    //     setCountPage(Math.ceil(filteredShippers.length / 5)); // Cập nhật tổng số trang
    //
    //     // Hiển thị thông báo nếu không tìm thấy shipper
    //     if (filteredShippers.length === 0) {
    //         setSearchResultMessage("Không có shipper nào được tìm thấy.");
    //     } else {
    //         setSearchResultMessage("");
    //     }
    // }, [fillterNum, originalShippers]); // Lắng nghe sự thay đổi của fillterNum hoặc originalShippers
    //

    // useEffect(() => {
    //     setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5)); // Slicing dữ liệu theo trang
    //     if (filteredShippers.length === 0) {
    //         setSearchResultMessage("Không có shipper nào được tìm thấy.");
    //     } else {
    //         setSearchResultMessage("");
    //     }
    // }, [filteredShippers, panage]);
    //
    // console.log("Original shippers:", shippers);
    // const data = [];

    // shippers.map((shipper, index) =>
    //     data.push({
    //         key: index,
    //         phone: shipper?.phone,
    //         fullName: shipper?.fullName,
    //         idCard: shipper?.idCard,
    //         point: shipper?.point,
    //         upPoint: [shipper?.phone, shipper?.point],
    //         id: shipper?._id,
    //         address: shipper?.address,
    //         status: shipper?.statusShipper,
    //     })
    // );
    // console.log("Shipper data:", shippers);

    // console.log("Data:", data);
    // const columns = [
    //     // {
    //     //   title: "ID",
    //     //   dataIndex: "id",
    //     // },
    //     {
    //         title: "Tên shipper",
    //         dataIndex: "fullName",
    //     },
    //     {
    //         title: "Địa chỉ hiện tại",
    //         dataIndex: "address",
    //     },
    //     // {
    //     //   title: "Số điện thoại",
    //     //   dataIndex: "phone",
    //     // },
    //     // {
    //     //   title: "Điểm",
    //     //   dataIndex: "point",
    //     // },
    //     // {
    //     //   title: "Nạp điểm",
    //     //   dataIndex: "upPoint",
    //     //   render: ([phone, point]) => {
    //     //     return (
    //     //       <Button
    //     //         className="bg-[#00A56F] px-4 py-2 rounded-full"
    //     //         variant="success"
    //     //         onClick={(e) => {
    //     //           OpenModalUpPoint(phone);
    //     //         }}
    //     //       >
    //     //         <strong className="text-white">Nạp điểm</strong>
    //     //       </Button>
    //     //     );
    //     //   },
    //     // },
    //
    //     {
    //         title: "Trạng thái hoạt động",
    //         dataIndex: "status",
    //         render: (text, id) => (
    //             <div id="dropdown-basic">
    //                 <div className="w-[150px] flex justify-between items-center px-[10px] py-[5px]  rounded-[3px]">
    //         <span
    //             className={`font-bold ${
    //                 text === true ? "text-[#00E974]" : "text-[#FF1B1B]"
    //             } `}
    //         >
    //           {text === true ? "Đang mở" : "Đang tắt"}
    //         </span>
    //                 </div>
    //             </div>
    //         ),
    //     },
    // ];

    // const handleFilter = () => {
    //     console.log("Searching for phone number or name:", fillterNum); // Debugging log
    //     setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5)); // Slicing dữ liệu theo trang
    //     if (filteredShippers.length === 0) {
    //         setSearchResultMessage("Không có shipper nào được tìm thấy.");
    //     } else {
    //         setSearchResultMessage("");
    //     }
    // };

    const handleReloadLocation = async () => {
        await Notiflix.Loading.pulse();

        socketEmit("refresh_address_to_shipper");
        // Đợi 2 giây cho app lấy tất cả vị trí của shipper
        setTimeout(() => {
            // getlist();
            getAllList()
             Notiflix.Loading.remove();
        }, 2000);


    };
    const shippersWithLocation = allShippers
        .map((shipper) => {
            if (shipper?.location) {
                const [latitude, longitude] = shipper.location.split(",").map(Number);
                return {...shipper, latitude, longitude};
            }
            return null; // Giữ null nếu không có location
        })
        .filter((shipper) => shipper !== null); // Loại bỏ các giá trị null


    const [viewport, setViewport] = useState({
        latitude: 10.9035, // Đồng Nai, Việt Nam
        longitude: 107.2348, // Đồng Nai, Việt Nam
        zoom: 8, // Phóng to vừa đủ để xem chi tiết Đồng Nai
        bearing: 0,
        pitch: 0,
        transitionDuration: 5000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubic,


    });
    const [popupInfo, setPopupInfo] = useState(null);

    return (
        <div className="manager-pane flex-1 flex flex-col h-[100vh]">
            <div className="flex flex-col overflow-scroll">
                <MapGL
                    {...viewport}
                    width="100%"
                    height="calc(100vh - 64px)"
                    onViewportChange={setViewport}
                    goongApiAccessToken={'2E6PTjM83XdVHBq6dbDaiYpQj15UTkt9wWPSHdf8'}

                >
                    <div className={`flex gap-6 absolute top-4 right-4 z-10 bg-white`}>
                        <div
                            className="flex gap-[4px] items-center px-2 py-3 rounded-xl border-[1px] border-solid border-[#000] cursor-pointer"
                            onClick={handleReloadLocation}
                        >
                            <IoEarthOutline size={24} color="#000"/>
                            <span className="whitespace-nowrap text-[14px]">Cập nhật lại vị trí hiện tại</span>
                        </div>
                    </div>
                    {
                        shippersWithLocation && shippersWithLocation.length > 0 &&
                        <Pins data={shippersWithLocation} onClick={setPopupInfo}/>
                    }
                    {
                        popupInfo && (
                            <Popup
                                tipSize={5}
                                anchor="top"
                                longitude={popupInfo.longitude}
                                latitude={popupInfo.latitude}
                                closeOnClick={false}
                                onClose={setPopupInfo}
                            >
                                <ShipperInfo info={popupInfo}/>
                            </Popup>
                        )
                    }
                    <GeolocateControl style={geolocateStyle}/>
                    <FullscreenControl style={fullscreenControlStyle}/>
                    <NavigationControl style={navStyle}/>
                    <ScaleControl style={scaleControlStyle}/>
                </MapGL>
                {/*<div className="flex gap-4 mx-4 my-2 justify-between items-center">*/}
                {/*    <InputForm>*/}
                {/*        <Input*/}
                {/*            placeholder="Nhập số điện thoại hoặc tên"*/}
                {/*            onChange={(e) => setFillterNum(e.target.value)}*/}
                {/*            value={fillterNum}*/}
                {/*        />*/}
                {/*        <ImgForm onClick={handleFilter}>*/}
                {/*            <img className="w-[16px]" src={searchIcon}/>*/}
                {/*        </ImgForm>*/}
                {/*    </InputForm>*/}


                {/*</div>*/}


                {/*<div className="flex-1 h-full  overflow-y-scroll ">*/}
                {/*    {searchResultMessage && (*/}
                {/*        <div className="text-center text-red-500">*/}
                {/*            {searchResultMessage}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*    <Table columns={columns} dataSource={data}/>*/}
                {/*</div>*/}

                {/*<div className="w-full flex justify-center relative px-[20px] py-[20px]">*/}
                {/*    <div className="w-fit flex text-center border-2 border-[#ccc]">*/}
                {/*        <div*/}
                {/*            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"*/}
                {/*            onClick={handleLeft}*/}
                {/*        >*/}
                {/*            &#x3c;*/}
                {/*        </div>*/}
                {/*        <div className="text-[23px] font-bold mx-[40px]">*/}
                {/*            {countPage ? `${panage}/${countPage}` : panage}*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"*/}
                {/*            onClick={handleRight}*/}
                {/*        >*/}
                {/*            &#x3e;*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default TabShipperLocation;
