import React, { useContext, useState, useEffect } from 'react'
import Notiflix from 'notiflix';
import { format } from 'date-fns'
import { Table } from 'antd';
import { getNotifications, deleteNotifications } from '../../../services/notificationService'
import IconBack from '../../../assets/icon/IconBack.png';
import plusIcon from '../../../assets/icon/plusIcon.png';
import remove from '../../../assets/icon/remove.png';
import editIcon from '../../../assets/icon/editIcon.png';
import { MedalContext } from '../../../context/Context';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Loading } from "notiflix";
import ModalCreateNotify from '../../../components/UI/Modals/ModalCreateNotify';
import {
    Header,
    HeaderLeft,
    BackIcon,
    TitleHeading,
    HeaderRight,
    PlusIcon,
    TextBtn,
} from './PageNotify.styles'
import { Axios } from 'axios';
import AxiosClient from '../../../services/axiosClient';

function PageNotify() {

    const { setIsModalCreateNotifyVisible, setPageNoti } = useContext(MedalContext);
    const [params, setsParams] = useState({ page: '1', limit: '4' })
    const [datas, setDatas] = useState();
    const [type, setType] = useState();
    const [notis, setNotis] = useState([])
    const [countPage, setCountPage] = useState()
    const [panage, setPanage] = useState(1)
    const [loading, setLoading] = useState(true);
    const [notiContext, setNotiContext] = useState();
    // get img firebase
    const storage = getStorage();
    const handleImg = async (img) => {
        return `https://api.tienphongship.thomi.com.vn/assets/NOTIFICATION_IMAGE/${img}`
    }
    // handle left btn pange
    const handleLeft = () => {
        setLoading(true)
        if (panage <= 1) {
            setPanage(1)
            setLoading(false)
            Notiflix.Notify.warning('trang không tồn tại')
        } else {
            setPanage(prev => prev - 1)
            setsParams({ ...params, page: panage - 1 })
        }
    }
    // handle right btn pange
    const handleRight = () => {
        setLoading(true)
        if (panage >= countPage) {
            Notiflix.Notify.warning('trang không tồn tại')
            setLoading(false)
            return
        } else {
            setPanage(prev => prev + 1)
            setsParams({ ...params, page: panage + 1 })
        }
    }
    // get list noti
    const getlist = async () => {
        if (params) {
            await getNotifications(params)
                .then(async (notis) => {
                    console.log(notis);
                    for (let i = 0; i < notis.data.data.length; i++) {
                        try {
                            notis.data.data[i].photo = await handleImg(notis.data.data[i].photo)
                        }
                        catch (e) {
                            notis.data.data[i].photo = "https://icon-library.com/images/error-icon-4_19035.png"
                        }
                    }
                    notis.data.data ? setLoading(false) : setLoading(true)
                    setNotis(notis.data.data);
                    setCountPage(notis.data.countPage)
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            return
        }
    }
    useEffect(() => {
        getlist()
    }, [panage, params])

    const Delete = (id) => {
        deleteNotifications(id)
            .then(() => {
                Notiflix.Notify.success('Xoá thành công')
                getlist()
            })
            .catch(() => {
                Notiflix.Notify.failure('Xoá thất bại')
            })
    }
    const Create = (index) => {
        setType('create')
        setIsModalCreateNotifyVisible(true)
    }
    const Edit = (noti) => {
        setNotiContext(noti);
        setType('edit')
        setDatas()
        setIsModalCreateNotifyVisible(true)
    }

    const columns = [
        {
            dataIndex: 'img',
            render: (img) => (
                <img className='w-[130px] max-h-[180px]' src={img} />
            )
        },
        {
            dataIndex: 'decription',
            render: (props) => (
                <div>
                    <div className='font-bold'>{props.title}</div>
                    <div>{props.des}</div>
                    <div className='text-[#555555]'>{format(new Date(props.date), 'dd-MM-yyyy')}</div>
                </div>
            )
        },
        {
            dataIndex: 'edit',
            render: (noti) => (
                <img className="w-[25px] hover:cursor-pointer" src={editIcon} onClick={() => Edit(noti)} />
            )
        },
        {
            dataIndex: 'delete',
            render: (id) => (
                <img className="w-[30px] hover:cursor-pointer" src={remove} onClick={() => Delete(id)} />
            )
        },
    ];
    const data = [];

    notis.map((noti, i) => {
        data.push({
            key: i,
            img: noti.photo,
            decription: {
                title: noti.title,
                des: noti.content,
                date: noti.createdAt
            },
            edit: noti,
            delete: noti._id,
        });
    })

    useEffect(() => {
        return () => {
            setPageNoti(1)
        }
    }, [])
    // set loading
    useEffect(() => {
        if (loading) Loading.pulse();
        else Loading.remove();
    }, [loading]);

    useEffect(() => {
        AxiosClient.get('https://api.tienphongship.thomi.com.vn/noti?page=1&limit=10')
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    },[])
    return (
        <div className="page-notify">
            <Header>
                <HeaderLeft>
                    <BackIcon src={IconBack} onClick={() => setPageNoti(1)} />
                    <TitleHeading>Thiết lập thông báo</TitleHeading>
                </HeaderLeft>
                <HeaderRight onClick={() => Create()}>
                    <PlusIcon src={plusIcon} />
                    <TextBtn>Tạo mới</TextBtn>
                </HeaderRight>
            </Header>
            <div className="h-[630px] overflow-y-auto">
                <Table pagination={{ pageSize: 4 }} columns={columns} dataSource={data} />
                <div className="w-full flex justify-center ">
                    <div className="w-fit flex text-center border-2 border-[#ccc]">
                        <div
                            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                            onClick={handleLeft}
                        >
                            &#x3c;
                        </div>
                        <div className="text-[23px] font-bold mx-[20px]">{panage}</div>
                        <div
                            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                            onClick={handleRight}
                        >
                            &#x3e;
                        </div>
                    </div>
                </div>
            </div>
            <ModalCreateNotify type={type} data={datas} notiContext={notiContext} getlist={getlist} notis={notis} />
        </div>
    )
}

export default PageNotify