import AxiosClient from "./axiosClient";

export const login = (phone, password) => {
  return AxiosClient.post("auth/login", { phone, password });
};

export const getUser = () => {
  return AxiosClient.get("auth/info");
};

export const logoutUser = (data) => {
  return AxiosClient.post("auth/logout", data);
};

// thông tin shop
export const getInfoShop = () => {
  return AxiosClient.get("shop/info");
};

export const loginwidthzalo = (phoneNumber) => {
  return AxiosClient.post("zalo/sendotp", {
    phoneNumber: phoneNumber,
  });
};

export const validateOtZalo = (data) => {
  return AxiosClient.post("zalo/verifyotp", data);
};
