import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTable: "totalStatistical",
};

const tableSlice = createSlice({
  name: "currentTable",
  initialState,
  reducers: {
    setTable(state, action) {
      state.currentTable = action.payload;
    },
  },
});


export const tableSelector = (state) => state.currentTable.currentTable;
export const { setTable } = tableSlice.actions;
export default tableSlice.reducer;
