import "./styles.scss";
import {Table} from "antd";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import ModalShipper from "./ModalShipper";
import {useSelector} from "react-redux";
import {format} from "date-fns";
import Notiflix, {Loading} from "notiflix";
import Dropdown from "react-bootstrap/Dropdown";
import lock from "../../../assets/icon/lock.png";
import React, {useEffect, useState} from "react";
import unLock from "../../../assets/icon/unLock.png";
import downIcon from "../../../assets/icon/downIcon.png";
import closeIcon from "../../../assets/icon/closeIcon.png";
import lockGreen from "../../../assets/icon/lockGreen.png";
import searchIcon from "../../../assets/icon/searchIcon.png";
import {ImgForm, Input, InputForm} from "./TabUser.styles";
import {changeSttUser, createUserLocal, getListUser,} from "../../../services/userServies";
import {getListArea} from "../../../services/areaServices";
import {HiUserAdd} from "react-icons/hi";

function TabUser() {
    const user = useSelector((state) => state.auth.user);
    const role = useSelector((state) => state.role.role);
    const codeArea = useSelector((state) => state.area.codeArea);
    const [fillterNum, setFillterNum] = useState("");
    const [User, setUser] = useState([]);
    const [totalUser, setTotalUser] = useState();
    const [panage, setPanage] = useState(1);
    const [countPage, setCountPage] = useState();
    const [loading, setLoading] = useState(true);
    const [params, setsParams] = useState({
        page: "1",
        limit: "100",
        orderBy: "createdAt",
        query: "",
    });
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: {errors},
    } = useForm();

    // set modal create user
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // set modal create shipper
    const [showShipper, setShipperShow] = useState(false);
    const handleShipperClose = () => setShipperShow(false);
    const handleShipperShow = () => setShipperShow(true);

    const [selectedOption, setSelectedOption] = useState(null);
    const [listArea, setListArea] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");

    const dataOption = [];
    listArea.map((item) => {
        dataOption.push({
            value: item.codeArea,
            label: item.nameArea,
        });
    });

    const getArea = async () => {
        const res = await getListArea();
        return setListArea(res.data);
    };

    useEffect(() => {
        getArea();
    }, []);

    // set loading
    useEffect(() => {
        if (loading) Loading.pulse();
        else Loading.remove();
    }, [loading]);

    // handle left btn pange
    const handleLeft = () => {
        if (panage <= 1) {
            setPanage(1);
        } else {
            setPanage((prev) => prev - 1);
            setsParams({...params, page: panage - 1});
        }
    };

    // handle right btn pange
    const handleRight = () => {
        if (panage >= countPage) {
            return;
        } else {
            setPanage((prev) => prev + 1);
            setsParams({...params, page: panage + 1});
        }
    };

    // handle change status user
    const handleChangeStatus = async (text, id) => {
        if (text === "ACTIVE") {
            await changeSttUser(id, "INACTIVE");
            await getlist();
        } else {
            await changeSttUser(id, "ACTIVE");
            await getlist();
        }
    };

    // handle fillter
    const handleFilter = () => {
        setsParams({
            ...params,
            query: fillterNum,
            page: 1,
        });
    };

    // get list User
    const getlist = async () => {
        await getListUser(params)
            .then((users) => {
                setTotalUser(users.data.totalElement);
                setUser(users.data.data);
                setCountPage(users.data.countPage);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getlist();
    }, [panage, params]);

    // table shop
    const columns = [
        {
            title: "Sđt",
            dataIndex: "phone",
        },
        {
            title: "Tên",
            dataIndex: "name",
        },
        {
            title: "Giới tính",
            dataIndex: "gender",
        },
        {
            title: "Ngày sinh",
            dataIndex: "birthday",
        },
        {
            title: "Gmail",
            dataIndex: "gmail",
        },

        {
            title: "Trạng thái",
            dataIndex: "status",
            render: ([text, id, role]) => (
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div
                            className="w-fit flex items-center px-[10px] py-[5px] border-[1px] border-[#ccc] rounded-[3px]">
              <span
                  className={`font-bold ${
                      text == "ACTIVE" ? "text-[#00E974]" : "text-[#FF1B1B]"
                  } `}
              >
                {text == "ACTIVE" ? "Đang mở" : "Đang khoá"}
              </span>
                            <img
                                className="w-[17px] h-[24px] mx-[10px]"
                                src={text == "ACTIVE" ? lockGreen : lock}
                            />
                            {role.every((item) => {
                                return item != "ADMIN" && "SUPERADMIN";
                            }) && <img className="w-[15px] h-[9px]" src={downIcon}/>}
                        </div>
                    </Dropdown.Toggle>
                    {role.every((item) => {
                        return item != "ADMIN" && "SUPERADMIN";
                    }) && (
                        <div className="drop-status">
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div
                                        onClick={() => handleChangeStatus(text, id)}
                                        className={`${
                                            text == "ACTIVE" ? " bg-[#FF1B1B]" : " bg-[#00E974]"
                                        } text-white flex items-center justify-between font-bol px-[10px] py-[5px] py-[5px rounded-[3px]`}
                                    >
                                        {text == "ACTIVE" ? "Khoá" : "Mở"}
                                        <img className="w-[17px] h-[24px]" src={unLock}/>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    )}
                </Dropdown>
            ),
        },
    ];
    const data = [];
    console.log(User);

    User.map((user, index) =>
        data.push({
            key: index,
            phone: user?.phone,
            name: user?.fullName,
            gender: user?.sex,
            birthday: user?.dateOfBirth
                ? format(new Date(user?.dateOfBirth), "dd-MM-yyyy")
                : "",
            gmail: user?.email,
            status: [user?.status, user?._id, user?.role],
        })
    );
    // create user ảo
    const onSubmit = (data) => {
        const newData = {
            ...data,
        };
        delete newData.area;
        createUserLocal(newData.phone, newData.password)
            .then((res) => {
                Notiflix.Notify.success("Tạo thành công", res);
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                Notiflix.Notify.failure("Tạo không thành công ");
            });
    };

    return (
        <div className="manager-pane active flex-1 overflow-y-scroll ">
            {/* modal create user start */}
            <Modal show={show} onHide={handleClose} centered={true} size="lg">
                <form
                    className="px-[100px] py-[50px] flex flex-col gap-[50px] relative"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <img
                        src={closeIcon}
                        className="absolute top-[15px] right-[15px] w-[37px] cursor-pointer"
                        onClick={handleClose}
                    />
                    <div className="flex flex-col gap-[50px]">
            <span className="font-bold text-[30px] w-full text-center">
              Tạo tài khoản nội bộ
            </span>
                        <div className="flex flex-col gap-[30px]">
                            <div className="flex flex-col gap-[15px]">
                                <span className="text-[20px] font-bold">Tài khoản</span>
                                <input
                                    placeholder="Nhập số điện thoại"
                                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                                    {...register("phone", {
                                        required: true,
                                        pattern: /(84|0[1-9])+([0-9]{8,9})\b/g,
                                    })}
                                />
                                {errors?.phone?.type === "required" && (
                                    <p className="text-[#FF0000] text-[14px]">
                                        Vui lòng không bỏ trống ô này
                                    </p>
                                )}
                                {errors?.phone?.type === "pattern" && (
                                    <p className="text-[#FF0000] text-[14px]">
                                        Vui lòng nhập đúng số điện thoại
                                    </p>
                                )}
                            </div>
                            <div className="flex flex-col gap-[15px]">
                                <span className="text-[20px] font-bold">Mật khẩu</span>
                                <input
                                    placeholder="Nhập mật khẩu"
                                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                                    {...register("password", {
                                        required: true,
                                        minLength: 6,
                                    })}
                                />
                                {errors?.password?.type === "required" && (
                                    <p className="text-[#FF0000] text-[14px]">
                                        Vui lòng không bỏ trống ô này
                                    </p>
                                )}
                                {errors?.password?.type === "minLength" && (
                                    <p className="text-[#FF0000] text-[14px]">
                                        Tối thiểu 6 ký tự
                                    </p>
                                )}
                            </div>
                            {/* {role == 'SUPERADMIN' && (
                <Controller
                  control={control}
                  name='area'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <div className='flex flex-col'>
                      <span className='text-[20px] font-bold'>Khu vực</span>
                      <Select
                        name={name}
                        value={value}
                        options={dataOption}
                        placeholder="Chọn khu vực"
                        onChange={e => onChange(e)}
                        getOptionLabel={e => e.label}
                        getOptionValue={e => e.value}
                        className="text-[20px] rounded-md outline-none text-center"
                        classNames="flex rounded-md outline-none text-center"
                        isSearchable={false}
                      />
                      {errors?.area?.type === "required" && <p className='text-[#FF0000] text-[14px]'>Vui lòng chọn khu vực</p>}
                    </div>
                  )}
                />
              )} */}
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <button
                            type="submit"
                            className="px-[40px] py-[14px] text-[20px] font-bold text-white bg-gradient-to-r from-[#f0ab52] to-[#F8C74F] rounded cursor-pointer"
                        >
                            Xác nhận
                        </button>
                    </div>
                </form>
            </Modal>
            {/* modal create user end */}

            {showShipper && (
                <ModalShipper
                    handleShipperShow={handleShipperShow}
                    handleShipperClose={handleShipperClose}
                />
            )}
            <div className="flex flex-col h-full ">
                <div className="flex w-full justify-between items-center p-[20px] cursor-pointer">
                    <InputForm>
                        <Input
                            placeholder="Nhập số điện thoại"
                            onChange={(e) => setFillterNum(e.target.value)}
                            value={fillterNum}
                        />
                        <ImgForm onClick={handleFilter}>
                            <img className="w-[16px]" src={searchIcon}/>
                        </ImgForm>
                    </InputForm>

                    <div className="flex gap-4">
                        <div
                            className="flex gap-[13px] items-center px-[13px] py-[8px] bg-outline rounded-3xl border-[1px] border-solid border-[#F8C74F]"
                            onClick={handleShow}
                        >
                            <HiUserAdd size={30} className="mt-2" color="#fff"/>
                            <span className="whitespace-nowrap text-[#fff] text-[16px]">
                Tạo tài khoản
              </span>
                        </div>
                    </div>
                </div>

                <div className="flex-1 h-full overflow-y-scroll">
                    <Table
                        pagination={{pageSize: 100}}
                        columns={columns}
                        dataSource={data}
                    />
                </div>
                <div className="w-full flex justify-center relative px-[20px] py-[20px]">
                    <div className="flex gap-[5px] absolute left-[20px] text-[17px] top-[50%] translate-y-[-50%]">
                        <span className="text-[#555555]">Tổng User:</span>
                        <span className="font-bold">{totalUser}</span>
                    </div>
                    <div className="w-fit flex text-center border-2 border-[#ccc]">
                        <div
                            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                            onClick={handleLeft}
                        >
                            &#x3c;
                        </div>
                        <div className="text-[23px] font-bold mx-[20px]">
                            {countPage ? `${panage}/${countPage}` : panage}
                        </div>
                        <div
                            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                            onClick={handleRight}
                        >
                            &#x3e;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabUser;
