import AxiosClient from "./axiosClient";

export const getListShipper = (params) => {
  return AxiosClient.get("shipper", { params: params });
};

export const getListShipperByArea = (params) => {
  return AxiosClient.get("shipper/", { params: params });
};

export const upPointShipper = (data) => {
  return AxiosClient.post("shipper/addPoints", data);
};

export const changeStatusShipper = (_id) => {
  return AxiosClient.put(`shipper/verify/${_id}`);
};

export const deleteShipper = (_id) => {
  return AxiosClient.delete(`shipper/delete/${_id}`);
}