import React from "react";
import { GrNotes } from "react-icons/gr";

function ListBoxStatistics({ report, type, table }) {
  var formatter = new Intl.NumberFormat("vi", {
    style: "currency",
    currency: "VND",
  });
  return (
    <div>
      {table === "totalShipper" ? (
        <div className="flex my-[20px] flex-wrap">
          <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[4px]">{`Tổng Đơn`}</div>
              <div className="text-[17px] font-bold ">
                {report?.totalOrder ? report?.totalOrder : 0} Đơn
              </div>
            </div>
          </div>

          <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#737373] mb-[7px]">
                Tổng tiền Voucher
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalVoucher ? report.totalVoucher : 0}
              </div>
            </div>
          </div>

          <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng tiền Ship
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalShip
                  ? formatter.format(report?.totalShip)
                  : 0}
              </div>
            </div>
          </div>

          <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Phí bán hàng
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalPrice
                  ? formatter.format(report?.totalPrice)
                  : 0}
              </div>
            </div>
          </div>

          {/* <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng tiền đơn đặt hàng phí dịch vụ
              </div>
              <div className="text-[17px] font-bold ">
                {formatter.format(report?.totalOrderServiceCharge)
                  ? formatter.format(report?.totalOrderServiceCharge)
                  : 0}
              </div>
            </div>
          </div> */}

          {/* <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng
              </div>
              <div className="text-[17px] font-bold ">
                {formatter.format(report?.totalShipperCollect)
                  ? formatter.format(report?.totalShipperCollect)
                  : 0}
              </div>
            </div>
          </div> */}

          <div className="mr-[19px] my-3 rounded-[7px] w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng phí dịch vụ
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalServiceFee
                  ? formatter.format(report?.totalServiceFee)
                  : 0}
              </div>
            </div>
          </div>
          

          <div className="rounded-[7px] my-3 w-[23%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalShipperCollect
                  ? formatter.format(report?.totalShipperCollect)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex my-[20px]">
          <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[4px]">
                {type ? type : `Tổng Đơn`}
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalOrder ? report?.totalOrder : 0} Suất
              </div>
            </div>
          </div>
          <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng COD
              </div>
              <div className="text-[17px] font-bold ">0</div>
            </div>
          </div>
          <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng Ship
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalShip
                  ? formatter.format(report?.totalShip)
                  : 0}
              </div>
            </div>
          </div>
          <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Phí bán hàng
              </div>
              <div className="text-[17px] font-bold ">
                {report?.totalPrice
                  ? formatter.format(report?.totalPrice)
                  : 0}
              </div>
            </div>
          </div>
          {/* <div className="rounded-[7px] w-[20%] flex items-start border-[2px] border-outline py-[5px] px-[10px]">
            <div className="mr-[15px]">
              <GrNotes size={20} color="#F8C74F" />
            </div>
            <div>
              <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">
                Tổng tiền thành công
              </div>
              <div className="text-[17px] font-bold ">
                {formatter.format(report?.totalComplete)
                  ? formatter.format(report?.totalComplete)
                  : 0}
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default ListBoxStatistics;
