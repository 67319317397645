import React, { useContext, useState, useEffect } from "react";
import { getListRQuser } from "../../../services/RQuserServices";
import { MedalContext } from "../../../context/Context";
import Dropdown from "react-bootstrap/Dropdown";
import { format } from "date-fns";
import { Table } from "antd";
import downIcon from "../../../assets/icon/downIcon.png";
import "./styles.scss";
import ModalType from "../../../components/UI/Modals/ModalType";
import { Loading } from "notiflix";
import { changeSttRQShop } from "../../../services/RQuserServices";
import Notiflix from "notiflix";
import { useSelector } from "react-redux";

function TabUser() {
  const user = useSelector((state) => state.auth.user);
  const { render, setIsModalTypeVisible, setRegetnumRequest } =
    useContext(MedalContext);

  const [params, setsParams] = useState({
    page: "1",
    orderBy: "createdAt",
    codeArea: user.codeArea,
  });
  const [User, setUser] = useState([]);
  const [panage, setPanage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) Loading.pulse();
    else Loading.remove();
  }, [loading]);

  const handleType = (id) => {
    setId(id);
    setIsModalTypeVisible(true);
  };

  const handleCancel = (id) => {
    Notiflix.Loading.pulse();
    changeSttRQShop(id, { status: "REFUSE" })
      .then(() => {
        Notiflix.Notify.success("Huỷ thành công");
        getlist();
        setRegetnumRequest(Math.random());
        Notiflix.Loading.remove();
      })
      .catch(() => {
        Notiflix.Notify.failure("Huỷ thất bại");
      });
  };

  const handleLeft = () => {
    if (panage > 1) {
      setPanage((prev) => prev - 1);
    }
  };

  const handleRight = () => {
    if (panage < countPage) {
      setPanage((prev) => prev + 1);
    }
  };

  const status = (text) => {
    switch (text) {
      case "ACCEPT":
        return <span className="text-[#00E974]">Đã duyệt</span>;
      case "PENDDING":
        return <span className="text-[#002BFF]">Đang chờ</span>;
      case "REFUSE":
        return <span className="text-[#FF0000]">Đã huỷ</span>;
      default:
        return null;
    }
  };

  const getlist = async () => {
    await getListRQuser(params)
      .then((users) => {
        // Sắp xếp danh sách theo trạng thái PENDING trước, sau đó là ngày tạo gần nhất
        const sortedUsers = users.data.data.sort((a, b) => {
          if (a.status === "PENDDING" && b.status !== "PENDDING") return -1;
          if (a.status !== "PENDDING" && b.status === "PENDDING") return 1;
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        // Phân trang tự động và kiểm tra nếu như trạng thái đang là PENDING thì thêm vào ở trang 1
        const paginatedUsers = [];
        let currentPage = [];
        sortedUsers.forEach((user) => {
          if (
            (currentPage.length < 5 && user.status === "PENDDING") ||
            currentPage.length < 5 ||
            user.status === "PENDDING"
          ) {
            currentPage.push(user);
          } else {
            paginatedUsers.push(currentPage);
            currentPage = [user];
          }
        });
        if (currentPage.length > 0) {
          paginatedUsers.push(currentPage);
        }

        setUser(paginatedUsers);
        setCountPage(paginatedUsers.length);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getlist();
  }, [render]);

  const columns = [
    {
      title: "Nội dung",
      dataIndex: "decription",
    },
    {
      title: "Ngày",
      dataIndex: "createdAt",
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Sđt",
      dataIndex: "phone",
    },
    {
      title: "Gmail",
      dataIndex: "gmail",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: ([text, id]) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="w-fit flex items-center px-[10px] py-[2px] border-[1px] border-[#ccc] rounded-[3px]">
              {status(text)}
              {text === "ACCEPT" || text === "REFUSE" ? (
                ""
              ) : (
                <img className="w-[12px] h-[7px] ml-[10px]" src={downIcon} />
              )}
            </div>
          </Dropdown.Toggle>
          {text !== "ACCEPT" && text !== "REFUSE" && (
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleType(id)}>
                Duyệt yêu cầu
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleCancel(id)}>
                Huỷ yêu cầu
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
    },
  ];

  const data =
    User[panage - 1]?.map((user, index) => ({
      key: index,
      decription: "Yêu cầu mở shop",
      phone: user?.user?.phone,
      name: user?.user?.fullName,
      createdAt: user?.createdAt
        ? format(new Date(user?.createdAt), "dd-MM-yyyy")
        : "",
      gmail: user?.user?.email,
      status: [user?.status, user?._id],
    })) || [];

  return (
    <div>
      <Table pagination={false} columns={columns} dataSource={data} />
      <div className="w-full flex justify-center">
        <div className="w-fit flex text-center border-2 border-[#ccc]">
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleLeft}
          >
            &#x3c;
          </div>
          <div className="text-[23px] font-bold mx-[20px]">{`${panage}/${countPage}`}</div>
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleRight}
          >
            &#x3e;
          </div>
        </div>
      </div>
      <ModalType id={id} />
    </div>
  );
}

export default TabUser;
