import React, { useEffect, useState } from "react";
import { RC } from "./ReportChart.styles";
import { Pie } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ListBoxStatistics from "./ListBoxStatistics";
import TableStatiscal from "./TableStatiscal";
import { useDispatch, useSelector } from "react-redux";
import { tableSelector } from "./tableSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

const ReportChart = (props) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: "# of Votes",
        data: [
          props.report.totalComplete,
          props.report.totalWaitingForProduct,
          props.report.totalCancel,
          props.report.totalDelivery,
          props.report.totalPending,
        ],
        backgroundColor: [
          "#01FF0A",
          "#F67A28",
          "#FF1F1F",
          "#F9128F",
          "#6400FA",
        ],
        borderColor: ["#01FF0A", "#F67A28", "#FF1F1F", "#F9128F", "#6400FA"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        color: "#fff",
        font: {
          size: 13,
          weight: "bold",
        },
        align: "end",
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  const plugins = [ChartDataLabels];
  // console.log("thí is pros", props);
  const currentTable = useSelector(tableSelector);

  return (
    data &&
    plugins &&
    props.report && (
      <RC.Container>
        <ListBoxStatistics report={props.report} type={props.type} table={currentTable} />
        <RC.Body>
          {/* <RC.BodyLeft>
            <RC.Header>Biểu đồ hiển thị đơn hàng</RC.Header>
            <RC.ChartAreaShadow>
              <RC.ChartArea>
                <Pie data={data} options={options} plugins={plugins} />
              </RC.ChartArea>
            </RC.ChartAreaShadow>
          </RC.BodyLeft> */}
          <RC.BodyRight>
            <TableStatiscal props={props?.report} />
          </RC.BodyRight>
        </RC.Body>
      </RC.Container>
    )
  );
};

export default ReportChart;
