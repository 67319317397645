import { io } from "socket.io-client";
import { API_ENDPOINT } from "./axiosClient";

export const socket = io(API_ENDPOINT);

export const socketOn = (eventName, callback) => {
  return socket.on(eventName, callback);
};

export const socketOff = (eventName) => {
  return socket.off(eventName);
};

export const socketDisconnect = () => {
  return socket.disconnect();
};

export const socketEmit = (eventName, origin) => {
  return socket.emit(eventName, origin);
};
