import React, { createContext, useState } from 'react'
import { format } from "date-fns";

const MedalContext = createContext()

const Context = ({ children }) => {
    const [active, setactive] = useState(1);
    const [pageNoti, setPageNoti] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalEditVisible, setIsModalEditVisible] = useState(false);
    const [isModalEditMangerVisible, setIsModalEditMangerVisible] = useState(false);
    const [isModalEditShipVisible, setIsModalEditShipVisible] = useState(false);
    const [isModalEditNotifyVisible, setIsModalEditNotifyVisible] = useState(false);
    const [isModalCreateNotifyVisible, setIsModalCreateNotifyVisible] = useState(false);
    const [isModalTypeVisible, setIsModalTypeVisible] = useState(false);
    const [render, setRender] = useState('');
    const [renderPage, setRenderPage] = useState('');
    const [reRender, setReRender] = useState('');
    const [order, setOrder] = useState();
    const [idUpdate, setIdUpdate] = useState();
    const [fillterDate, setFillterDate] = useState();
    const [regetnumRequest, setRegetnumRequest] = useState();
    const [status, setStatus] = useState(
        {
            status: '',
            query: '',
            type: '',
            codeArea: '',
            page: '1',
            orderBy: 'createdAt',
            startDate: format(new Date(), "yyyy-MM-dd"),
            endDate: format(new Date(), "yyyy-MM-dd"),
        }
    );
    const [page, setPage] = useState(
        {
            page: '1',
        }
    );

    return (
        <MedalContext.Provider value=
            {{
                active, setactive,
                pageNoti, setPageNoti,
                isModalVisible, setIsModalVisible,
                isModalEditVisible, setIsModalEditVisible,
                isModalEditMangerVisible, setIsModalEditMangerVisible,
                isModalEditShipVisible, setIsModalEditShipVisible,
                isModalEditNotifyVisible, setIsModalEditNotifyVisible,
                isModalCreateNotifyVisible, setIsModalCreateNotifyVisible,
                isModalTypeVisible, setIsModalTypeVisible,
                render, setRender,
                status, setStatus,
                page, setPage,
                renderPage, setRenderPage,
                order, setOrder,
                idUpdate, setIdUpdate,
                reRender, setReRender,
                fillterDate, setFillterDate,
                regetnumRequest, setRegetnumRequest,
            }}>
            {children}
        </MedalContext.Provider>
    )
}

export default Context
export { MedalContext }