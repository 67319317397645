import * as React from 'react';

function ShipperInfo({info}) {

    return (
        <div>
            <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                    <div className="font-bold">Họ và tên:</div>
                    <div>{info.fullName}</div>
                </div>
                <div className="flex gap-2">
                    <div className="font-bold">Số điện thoại:</div>
                    <div>{info.phone}</div>
                </div>
                <div className="flex gap-2">
                    <div className="font-bold">CCCD:</div>
                    <div>{info.idCard}</div>
                </div>
                <div className="flex gap-2">
                    <div className="font-bold">Địa chỉ:</div>
                    <div>{info.address}</div>
                </div>

            </div>
        </div>
    );
}

export default React.memo(ShipperInfo);