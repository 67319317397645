import tw from "tailwind-styled-components";

export const FormWrapper = tw.div`
    flex 
    justify-between 
    px-[20px] 
    pt-[10px]
    mb-[20px]
`
export const TitileHeading = tw.span`
    text-[20px] 
    font-bold 
    text-primary
`
export const FormInfoUser = tw.div`
    flex 
    items-center
`
export const FormToggle = tw.div`
    mt-[10px]
    mb-[20px]
`
export const ButtonUser = tw.button`
    px-[25px]
    py-[7px]
    text-[17px]
    font-bold
    text-black
    rounded-[7px]
    border-[1px]
    border-black
    mr-[20px]
`
export const ButtonShop = tw.button`
    px-[25px]
    py-[7px]
    text-[17px]
    font-bold
    text-black
    rounded-[7px]
    border-[1px]
    border-black
    mr-[20px]
`
export const BtnFileExcel = tw.div`
    flex 
    items-center 
    px-[9px] 
    py-[6px] 
    border-[1px] 
      border-outline 
    rounded-default 
    w-[160px] 
    mt-[5px]
    hover:cursor-pointer
`
export const TextBtn = tw.span`
    text-[15px] 
    font-semibold 
    ml-[10px]
`