import React, { useState, useEffect } from "react";
import { RF, Input, InputForm, ImgForm } from "./ReportFilter.styles";
import ReportFilterDotIcon from "../../../assets/icons/ReportFilterDotIcon.png";
import DatePickerReport from "../../../components/UI/DatePicker/DatePickerReport";
import searchIcon from '../../../assets/icon/searchIcon.png'

const ReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onPressedFilter,
  type,
  fillterName,
  setFillterName,
  getData,
}) => {
  const handleFilter = () => {
    getData()
  }
  return (
    <RF.Container>
      {/* {
        type &&
        <InputForm>
          <Input
            placeholder="Nhập tên nhân viên"
            onChange={(e) => setFillterName(e.target.value)}
            value={fillterName}
          />
          <ImgForm onClick={handleFilter}>
            <img className="w-[16px]" src={searchIcon} />
          </ImgForm>
        </InputForm>
      } */}
      {/* <RF.FormControl>
        <RF.Label>
          Từ
        </RF.Label>
        <DatePickerReport
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </RF.FormControl>
      <RF.FormControl>
        <RF.Label>
          Tới
        </RF.Label>
        <DatePickerReport
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
      </RF.FormControl> */}
      {/* <RF.FormControl>
        <RF.Button
          onClick={onPressedFilter}
        >
          Lọc
        </RF.Button>
      </RF.FormControl> */}
    </RF.Container>
  );
};

export default ReportFilter;
