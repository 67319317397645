import React from "react";
import { Space, Table, Tag } from "antd";
import { useSelector } from "react-redux";
import { tableSelector } from "./tableSlice";

function TableStatiscal({ props }) {
  var formatter = new Intl.NumberFormat("vi", {
    style: "currency",
    currency: "VND",
  });

  const curentTable = useSelector(tableSelector);
  console.log("props", props);

  // create datatable
  const dataTable = props.table && [
    {
      status: "Đã giao",
      backgroundColor: "#01FF0A",
      totalOrder: props.table[0]?.totalOrder,
      totalPrice: props.table[0]?.totalPrice,
      totalShip: props.table[0]?.totalShip,
    },
    {
      status: "Đã huỷ",
      backgroundColor: "#FF1F1F",
      totalOrder: props.table[1]?.totalOrder,
      totalPrice: props.table[1]?.totalPrice,
      totalShip: props.table[1]?.totalShip,
    },
    {
      status: "Đang chờ",
      backgroundColor: "#6400FA",
      totalOrder: props.table[2]?.totalOrder,
      totalPrice: props.table[2]?.totalPrice,
      totalShip: props.table[2]?.totalShip,
    },
    {
      status: "Chờ lấy hàng",
      backgroundColor: "#F67A28",
      totalOrder: props.table[3]?.totalOrder,
      totalPrice: props.table[3]?.totalPrice,
      totalShip: props.table[3]?.totalShip,
    },
    {
      status: "Đang giao",
      backgroundColor: "#F9128F",
      totalOrder: props.table[4]?.totalOrder,
      totalPrice: props.table[4]?.totalPrice,
      totalShip: props.table[4]?.totalShip,
    },
  ];
  const columns = [
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <div className="flex items-center">
          <div
            className="w-[20px] h-[20px] rounded-sm mr-2"
            style={{ backgroundColor: status.color }}
          ></div>
          <div>{status.status}</div>
        </div>
      ),
    },
    {
      title: "Số đơn",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "Tổng COD",
      dataIndex: "cod",
      key: "orderNum",
    },
    {
      title: "Tổng tiền hàng",
      dataIndex: "totalMoney",
      key: "totalMoney",
    },
    {
      title: "Tổng tiền ship",
      dataIndex: "totalShip",
      key: "totalShip",
    },
  ];

  const shipperColumns = [
    {
      title: <div style={{ fontSize: "16px" }}>Tên Nhân Viên</div>,
      dataIndex: "shipperName",
      key: "shipperName",
      sorter: (a, b) => a.shipperName.localeCompare(b.shipperName),
    },
    {
      title: <div style={{ fontSize: "16px" }}>SDT</div>,
      dataIndex: "phone",
      key: "phone",
      align: "center",
      sorter: (a, b) => a.shipperName.localeCompare(b.shipperName),
    },
    {
      title: <div style={{ fontSize: "16px" }}>Số đơn</div>,
      dataIndex: "orderNum",
      key: "orderNum",
      align: "center",
      sorter: (a, b) => a.orderNum - b.orderNum,
    },
    {
      title: <div style={{ fontSize: "16px" }}>Tổng tiền voucher</div>,
      dataIndex: "totalVoucher",
      key: "totalVoucher",
      align: "center",
    },
    // {
    //   title: <div style={{ fontSize: "16px" }}>Tổng tiền hàng</div>,
    //   dataIndex: "totalMoney",
    //   key: "totalMoney",
    //   align: "center",
    //   sorter: (a, b) => a.totalMoney - b.totalMoney,
    // },
    {
      title: <div style={{ fontSize: "16px" }}>Tổng tiền ship</div>,
      dataIndex: "totalShip",
      key: "totalShip",
      align: "center",
    },
    {
      title: <div style={{ fontSize: "16px" }}>Phí bán hàng</div>,
      dataIndex: "totalServiceCharge",
      key: "totalServiceCharge",
      align: "center",
    },
    {
      title: <div style={{ fontSize: "16px" }}>Phí dịch vụ</div>,
      dataIndex: "serviceMoney",
      key: "serviceMoney",
      align: "center",
    },
    {
      title: <div style={{ fontSize: "16px" }}>Tổng</div>,
      dataIndex: "total",
      key: "total",
      align: "center",
    },
  ];

  const data = [];
  const shipperData = [];

  // Đổ dữ liệu vào Thống kê tổng
  dataTable?.map((item, i) => {
    data.push({
      key: `${i}`,
      status: { status: item?.status, color: item?.backgroundColor },
      orderNum: item?.totalOrder,
      cod: "0đ",
      totalMoney: formatter.format(item?.totalPrice),
      totalShip: formatter.format(item?.totalShip),
    });
  });

  // Đổ dữ liệu vào Thống kê Shipper
  if (curentTable === "totalShipper" && props?.data) {
    props?.data.map((item, i) => {
      shipperData.push({
        key: `${i}`,
        shipperName: item?.fullName,
        phone: item?.phone,
        orderNum: item?.orderCount,
        totalVoucher: formatter.format(item?.totalVoucher),
        totalMoney: formatter.format(item?.totalPrice),
        totalShip: formatter.format(item?.totalShip),
        serviceMoney: formatter.format(item?.totalServiceFee),
        total: formatter.format(item?.total),
        totalServiceCharge: formatter.format(item?.totalServiceCharge),
      });
    });
  }
  return (
    <div className="tableChart min-h-[400px]">
      <Table
        columns={curentTable === "totalShipper" ? shipperColumns : columns}
        dataSource={curentTable === "totalShipper" ? shipperData : data}
      />
    </div>
  );
}

export default TableStatiscal;
