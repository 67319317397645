import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState, useRef } from "react";
import closeIcon from "../../../assets/icon/closeIcon.png";
import { useForm, Controller } from "react-hook-form";
import Notiflix from "notiflix";
import { createShipper } from "../../../services/userServies";
import { useSelector } from "react-redux";
import { MedalContext } from "../../../context/Context";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { setCodeArea } from "../../../store/area/area";
import { getListArea } from "../../../services/areaServices";

const ModalShipper = ({ handleShipperShow, handleShipperClose }) => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state.auth.user);
   const role = useSelector((state) => state.role.role);

   const [selectedOption, setSelectedOption] = useState(null);
   const [listArea, setListArea] = useState([]);
   const [selectedValue, setSelectedValue] = useState("");

   const dataOption = [];
   listArea.map((item) => {
      dataOption.push({
         value: item.codeArea,
         label: item.nameArea,
      });
   });

   const getArea = async () => {
      const res = await getListArea();
      return setListArea(res.data);
   };

   useEffect(() => {
      getArea();
   }, []);

   const { setReRender } = useContext(MedalContext);

   const {
      register,
      handleSubmit,
      watch,
      control,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      Notiflix.Loading.pulse("Vui lòng đợi trong giây lát");
      const newFormData = {
         ...data,
         codeArea: data.area.value,
         licensePlate: "000000",
         typeOfVehicle: "honda",
      };
      await delete newFormData.area;
      await createShipper(newFormData)
         .then(() => {
            setReRender(Math.random());
            Notiflix.Notify.success("Tạo thành công");
            handleShipperClose();
            Notiflix.Loading.remove();
         })
         .catch((err) => {
            console.log(err);
            Notiflix.Notify.failure("Tạo không thành công ");
            Notiflix.Loading.remove();
         });
   };

   return (
      <Modal show={handleShipperShow} onHide={handleShipperClose} centered={true} size="lg">
         <form className="px-[100px] py-[50px] flex flex-col relative gap-4" onSubmit={handleSubmit(onSubmit)}>
            <img
               src={closeIcon}
               className="absolute top-[15px] right-[15px] w-[37px] cursor-pointer"
               onClick={handleShipperClose}
            />
            <div className="flex flex-col ">
               <span className="font-bold text-[30px] w-full text-center">Tạo tài khoản shipper</span>
               <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                     <span className="text-[20px] font-bold">Họ tên</span>
                     <input
                        placeholder="Nhập họ tên"
                        className="p-[10px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                        {...register("fullName", {
                           required: true,
                        })}
                     />
                     {errors?.fullName?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng không bỏ trống ô này</p>
                     )}
                  </div>

                  <div className="flex flex-col gap-1">
                     <span className="text-[20px] font-bold">Số điện thoại</span>
                     <input
                        placeholder="Nhập số điện thoại"
                        className="p-[10px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                        {...register("phone", {
                           required: true,
                           pattern: /^0[0-9]{9,10}$/,
                        })}
                     />
                     {errors?.phone?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng không bỏ trống ô này</p>
                     )}
                     {errors?.phone?.type === "pattern" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng nhập đúng số điện thoại</p>
                     )}
                  </div>
                  <div className="flex flex-col gap-1">
                     <span className="text-[20px] font-bold">Mật khẩu</span>
                     <input
                        placeholder="Nhập mật khẩu"
                        className="p-[10px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                        {...register("password", {
                           required: true,
                           minLength: 5,
                        })}
                     />
                     {errors?.password?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng không bỏ trống ô này</p>
                     )}
                     {errors?.password?.type === "minLength" && (
                        <p className="text-[#FF0000] text-[14px]">Tối thiểu 5 ký tự</p>
                     )}
                  </div>
                  <div className="flex flex-col gap-1">
                     <span className="text-[20px] font-bold">Chứng minh nhân dân</span>
                     <input
                        placeholder="Nhập Chứng minh nhân dân"
                        className="p-[10px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                        {...register("idCard", {
                           required: true,
                           pattern: /^[0-9]+$/, // Chỉ cho phép các ký tự số
                        })}
                     />
                     {errors?.idCard?.type === "required" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng không bỏ trống ô này</p>
                     )}
                     {errors?.idCard?.type === "pattern" && (
                        <p className="text-[#FF0000] text-[14px]">Vui lòng chỉ nhập số</p>
                     )}
                  </div>
                  {role == "SUPERADMIN" && (
                     <Controller
                        control={control}
                        name="area"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                           <div className="flex flex-col">
                              <span className="text-[20px] font-bold">Khu vực</span>
                              <Select
                                 name={name}
                                 value={value}
                                 options={dataOption}
                                 placeholder="Chọn khu vực"
                                 onChange={(e) => onChange(e)}
                                 getOptionLabel={(e) => e.label}
                                 getOptionValue={(e) => e.value}
                                 className="text-[20px] rounded-md outline-none text-center"
                                 classNames="flex rounded-md outline-none text-center"
                                 isSearchable={false}
                              />
                              {errors?.area?.type === "required" && (
                                 <p className="text-[#FF0000] text-[14px]">Vui lòng chọn khu vực</p>
                              )}
                           </div>
                        )}
                     />
                  )}
               </div>
            </div>
            <div className="w-full flex justify-center">
               <button
                  type="submit"
                  className="px-[40px] py-[14px] text-[20px] font-bold text-white bg-gradient-to-r from-[#f0ab52] to-[#F8C74F] rounded cursor-pointer">
                  Xác nhận
               </button>
            </div>
         </form>
      </Modal>
   );
};

export default ModalShipper;