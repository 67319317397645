import React, {useEffect} from 'react';
import Header from './components/Header'
import ReportChartTotal from './components/ReportChartTotal';
import ReportChartShipper from './components/ReportChartShipper';

const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

function Statistical() {

  useEffect(() => {
    $$('.ManagerBtn').forEach((item, index) => {
      item.addEventListener('click', () => {

        $('.ManagerBtn.active').classList.remove('active');
        item.classList.add('active');

        $('.report-pane.active').classList.remove('active');
        $$('.report-pane')[index].classList.add('active');
      })
    });
  })

  return (
    <div className="bg-[#F5F6FA]">
      <Header />
      <ReportChartTotal />
      <ReportChartShipper />
    </div>
  )
}

export default Statistical